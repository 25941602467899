import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Breadcrumbs,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';

import {
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons';

import { Spinner } from '../../components';
import {
  HorseDataWidget,
  HorseProfile,
  HorseTransactions,
  RelatedNews,
} from './components';
import { routes } from '../../const';

const useStyles = makeStyles(theme => {
  return {
    paper: {
      height: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    backLink: {
      display: 'inline-block',
      marginBottom: theme.spacing(3),
    },
    horseDataList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    horseImage: {
      width: '100%',
    },
    tab: {
      backgroundColor: theme.palette.primary.dark,
    }
  }
});

const InvestmentHorse = ({
  horse,
  isLoading,
  news,
  transactions,
}) => {

  const { horseName, serialNumber } = horse;

  const classes = useStyles();

  return (
    <>
      {isLoading ?
        <Grid
          item
          xs={12}
        >
          <Spinner />
        </Grid> :
        <>
          <Grid item xs={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" />}
              style={{marginBottom: '16px'}}
            >
              <Link
                component={RouterLink}
                to={routes.INVESTMENT}
              >
                Dashboard
              </Link>
              <Typography color="textPrimary">
                {horseName}
              </Typography>
            </Breadcrumbs>

            {/* Page Title */}
            <Typography variant="h3">{horseName}</Typography>
            <Typography
              color="textSecondary"
              component="small"
            >
              {serialNumber}
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={3}
          >
            <HorseDataWidget
              format="currency"
              title={'Investment Value'}
              value={horse.horseValue}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
          >
            <HorseDataWidget
              title={'Investment Shares'}
              value={`${horse.shares}%`}
            />
          </Grid>

          <Grid item xs={12}>
            <HorseProfile
              horseDetails={horse}
            />
          </Grid>

          {/* Related News */}
          <RelatedNews news={news} />

          {/* Transactions */}
          <Grid item xs={12}>
            <HorseTransactions
              transactions={transactions}
            />
          </Grid>

        </>
      }
    </>
  );
}

export default InvestmentHorse;
