import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import { Main as MainLayout } from './layouts';
import { routes } from './const';

import {

  // Auth
  SignIn as SignInView,

  // Horse
  InvestmentHorse as InvestmentHorseView,

  // Investment
  InvestmentDashboard as InvestmentDashboardView,

  // Company Transactions
  CompanyTransactions as CompanyTransactionsView,

  // Syndication Dashboard
  SyndicationDashboard as SyndicationDashboardView,

  // Horse details
  SyndicationHorseDetails as SyndicationHorseDetailsView,

  // User details
  UserDetails as UserDetailsView,

} from './views';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ isAuthenticated, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = ({isAuthenticated}) => {

  return (
    <Switch>
      <Redirect
        exact
        from={routes.ROOT}
        to={isAuthenticated ? routes.INVESTMENT : routes.LOGIN}
      />

      {/* Auth */}
      <Route
        exact
        path={routes.LOGIN}
        render={({ location }) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: routes.INVESTMENT,
                state: { from: location },
              }}
            />
          ) : (
            <SignInView />
          )
        }
      />

      {/* Syndication Horse */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.INVESTMENT_HORSE}/:investmentHorseId`}
      >
        <MainLayout>
          <InvestmentHorseView />
        </MainLayout>
      </PrivateRoute>

      {/* Investment Dashboard */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.INVESTMENT}
      >
        <MainLayout>
          <InvestmentDashboardView />
        </MainLayout>
      </PrivateRoute>

      {/* Company Transactions */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.COMPANY_TRANSACTIONS}
      >
        <MainLayout>
          <CompanyTransactionsView />
        </MainLayout>
      </PrivateRoute>

      {/* Syndications Dashboard */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.SYNDICATIONS}
      >
        <MainLayout>
          <SyndicationDashboardView />
        </MainLayout>
      </PrivateRoute>

      {/* Syndication Horse */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={`${routes.SYNDICATIONS}/:syndicationId`}
      >
        <MainLayout>
          <SyndicationHorseDetailsView />
        </MainLayout>
      </PrivateRoute>

      {/* User Details */}
      <PrivateRoute
        exact
        isAuthenticated={isAuthenticated}
        path={routes.USER_DETAILS}
      >
        <MainLayout>
          <UserDetailsView />
        </MainLayout>
      </PrivateRoute>

      {/* Redirect to root for all 404 pages */}
      <Redirect to={routes.ROOT} />
    </Switch>
  );
}

export default Routes;
