import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Spinner, Title } from '../../components';
import { HorseDataWidget, HorseProfile, HorseTransactions, RelatedNews } from './components';
import { routes } from '../../const';

const useStyles = makeStyles(theme => {
  return {
    paper: {
      height: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    backLink: {
      display: 'inline-block',
      marginBottom: theme.spacing(3),
    },
    horseDataList: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    horseImage: {
      width: '100%',
    },
    tab: {
      backgroundColor: theme.palette.primary.dark,
    }
  }
});

const SyndicationHorseDetails = ({
  currentShare,
  horse,
  isLoading,
  news,
  totalBalances,
  transactions,
}) => {

  const { horseName, serialNumber } = horse;

  const {
    depositBalance,
    incomeBalance,
    totalPrizeMoneyBalance,
  } = totalBalances;

  const classes = useStyles();

  return (
    <>
      {/* Back link */}
      <Grid item xs={12}>
        <Link component={RouterLink} to={routes.SYNDICATIONS} className={classes.backLink}>
          Back to Syndication Dashboard
        </Link>
      </Grid>

      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          {/* Page Title */}
          <Grid item xs={12}>
            <Title>{horseName}</Title>
            <Typography
              color="textSecondary"
              component="small"
            >
              {serialNumber}
            </Typography>
          </Grid>

          {/* Shares and Balance */}
          <Grid
            item
            xs
          >
            <HorseDataWidget
              title={'My Share'}
              value={`${currentShare}%`}
            />
          </Grid>
          <Grid
            item
            xs
          >
            <HorseDataWidget
              format='currency'
              title={'Total Balance'}
              value={depositBalance + incomeBalance}
            />
          </Grid>
          <Grid
            item
            xs
          >
            <HorseDataWidget
              format='currency'
              title={'Deposit'}
              value={depositBalance}
            />
          </Grid>
          <Grid
            item
            xs
          >
            <HorseDataWidget
              format='currency'
              title={'Income'}
              value={incomeBalance}
            />
          </Grid>

          <Grid
            item
            xs
          >
            <HorseDataWidget
              format='currency'
              title={'Total Prize Money'}
              value={totalPrizeMoneyBalance}
            />
          </Grid>

          <Grid item xs={12}>
            <HorseProfile
              horseDetails={horse}
            />
          </Grid>

          {/* Related News */}
          <RelatedNews news={news} />

          {/* Transactions */}
          <Grid item xs={12}>
            <Title>Transactions</Title>
            <HorseTransactions
              transactions={transactions}
            />
          </Grid>
        </>
      }
    </>
  );
}

export default SyndicationHorseDetails;
