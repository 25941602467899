import Parse from 'parse';

async function signInAction({ username, password }) {
  try {
    const user = await Parse.User.logIn(username, password);
    return true;
  } catch (error) {
    return false;
  }

}

export default signInAction;
