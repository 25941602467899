import React from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend
} from 'recharts';
import { Typography } from '@material-ui/core';

const COLORS = ['#5850EC', 'rgba(0,0,0,0.1)'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ShareChart = ({
  investmentDetails,
  summary,
}) => {

  const customerShares = investmentDetails.shares;
  const totalShares = summary.totalShares;
  const otherShares = totalShares - customerShares;
  const sharesRatio = `${customerShares}/${totalShares}`;

  const data = [
    { name: 'My Share', value: customerShares },
    { name: 'Other Shares', value: otherShares },
  ];

  return (
    <div style={{
      position: 'relative',
    }}>
      <Typography
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, calc(-50% - 10px))',
        }}
        variant="h4"
      >
        {sharesRatio}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            labelLine={false}
            innerRadius={80}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            paddingAngle={0}
          >
            {
              data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
            }
          </Pie>
          <Tooltip />
          <Legend iconType="circle" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ShareChart;
