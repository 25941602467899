import React, { useContext, useEffect, useState } from 'react';

import { RouteContext } from '../../App';

import {
  fetchCompanyTransactions,
} from './actions';


import CompanyTransactions from './CompanyTransactions';

const CompanyTransactionsContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ isLoading, setIsLoading ] = useState(true);

  // Table states
  const [ incomeTransaction, setIncomeTransaction ] = useState([]);
  const [ expenseTransaction, setExpenseTransaction ] = useState([]);

  // Total balances
  const [ totalIncome, setTotalIncome ] = useState(0);
  const [ totalExpense, setTotalExpense ] = useState(0);

  useEffect(() => {
    setCurrentRoute('company-transactions');
    _initData();
  }, []);

  const _initData = async () => {
    const result = await fetchCompanyTransactions();
    setIncomeTransaction(result.transactions.income);
    setExpenseTransaction(result.transactions.expense);
    setTotalIncome(result.sums.income);
    setTotalExpense(result.sums.expense);
    setIsLoading(false);
  };

  return(
    <CompanyTransactions
      expenseTransaction={expenseTransaction}
      incomeTransaction={incomeTransaction}
      isLoading={isLoading}
      totalExpense={totalExpense}
      totalIncome={totalIncome}
    />
  );
}

export default CompanyTransactionsContainer;
