import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import {
  AccountCircle as AccountCircleIcon,
  AttachMoney as AttachMoneyIcon,
  ExitToApp as ExitToAppIcon,
  Language as LanguageIcon,
} from '@material-ui/icons';

import { routes } from '../../../../../../const';
import { HorseIcon } from '../../../../../../components';

import { RouteContext } from '../../../../../../App';

const SidebarNav = ({ handleDrawerToggle, onSignOut }) => {

  const { currentRoute } = useContext(RouteContext);

  return (
    <List>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'investment'}
        to={routes.INVESTMENT}
      >
        <ListItemIcon>
          <AttachMoneyIcon />
        </ListItemIcon>
        <ListItemText primary="Investment" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'syndications'}
        to={routes.SYNDICATIONS}
      >
        <ListItemIcon>
          <HorseIcon />
        </ListItemIcon>
        <ListItemText primary="Syndications" />
      </ListItem>
      <ListItem
        button
        component={RouterLink}
        onClick={handleDrawerToggle}
        selected={currentRoute === 'details'}
        to={routes.USER_DETAILS}
      >
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary="My Details" />
      </ListItem>

      <ListItem
        button
        component="a"
        href="https://taniwhagroup.com"
        target="_blank"
      >
        <ListItemIcon>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText
          primary="Back to Website"
        />
      </ListItem>

      <ListItem
        button
        onClick={onSignOut}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
        />
      </ListItem>
    </List>
  );
}

export default SidebarNav;
