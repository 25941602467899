import Parse from 'parse';

const fetchStatements = async() => {
  try {
    const query = new Parse.Query('CompanyStatement');
    query.ascending('statementDate');
    const response = await query.find();

    const payload = response.map((statement) => {

      return {
        id: statement.id,
        statementDate: statement.get('statementDate'),
        totalCurrentAssets: statement.get('totalCurrentAssets'),
        totalAssets: statement.get('totalAssets'),
        totalCurrentLiabilities: statement.get('totalCurrentLiabilities'),
        totalLiabilities: statement.get('totalLiabilities'),
        totalEquity: statement.get('totalEquity'),
        totalTradingIncome: statement.get('totalTradingIncome'),
        grossProfit: statement.get('grossProfit'),
        netProfit: statement.get('netProfit'),
        netCashFlowsFromOperatingActivities: statement.get('netCashFlowsFromOperatingActivities'),
        supplierPurchase: statement.get('supplierPurchase'),
        creditSales: statement.get('creditSales'),
        averageAccountsPayable: statement.get('averageAccountsPayable'),
        avarageAccountsReceivable: statement.get('avarageAccountsReceivable'),
      }

    });

    return payload;

  } catch (error) {
    console.error('fetchCompanyStatements error', error);
  }

}

export default fetchStatements;
