import Parse from 'parse';

import { fetchCurrentCustomerAction } from '../../../actions';
import { handleParseError } from '../../../utils';

const getCurrentCustomer = async () => {
  const customerObj = await fetchCurrentCustomerAction();

  const customer = {
    id: customerObj.id,
    firstName: customerObj.get('firstName'),
    middleName: customerObj.get('middleName'),
    lastName: customerObj.get('lastName'),
    dateOfBirth: customerObj.get('dateOfBirth'),
    landline: customerObj.get('landline'),
    mobile: customerObj.get('mobile'),
    email: customerObj.get('email'),
    businessName: customerObj.get('businessName'),
    jobTitle: customerObj.get('jobTitle'),
    invoiceTitle: customerObj.get('invoiceTitle'),
    bankAccount: customerObj.get('bankAccount'),
    gstNumber: customerObj.get('gstNumber'),
  };

  return customer;
}

const fetchInitDataAction = async () => {

  try {

    const customer = await getCurrentCustomer();

    const user = Parse.User.current();

    const credentials = {
      username: user.get('username'),
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    }

    const payload = {
      status: 'success',
      credentials,
      customer,
    }

    return payload;

  } catch (error) {
    handleParseError(error);
    return {
      status: 'error',
    };
  }
}

export default fetchInitDataAction;
