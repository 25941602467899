import Parse from 'parse';

const fetchSummary = async () => {
  try {
    const investmentProductQuery = new Parse.Query('InvestmentProduct');

    const [
      investmentProduct,
      nextDividendDate,
    ] = await Promise.all([
      investmentProductQuery.first(),
      Parse.Cloud.run('fetchNextDividendDate'),
    ]);

    console.log('nextDividendDate', nextDividendDate, typeof nextDividendDate);

    const payload = {
      totalAssets: investmentProduct.get('totalAssets'),
      totalIncome: investmentProduct.get('totalIncome'),
      totalExpense: investmentProduct.get('totalExpense'),
      dividendDate: nextDividendDate.toLocaleDateString(),
      totalShares: investmentProduct.get('totalShares'),
    }

    return payload;

  } catch (error) {
    console.error('fetchSummary error', error);
  }

}

export default fetchSummary;
