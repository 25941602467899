import React, { PureComponent } from 'react';
import {
  Area, AreaChart, CartesianAxis, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const data = [
  {
    date: 'Jan',
    value: 50000,
  },
  {
    date: 'Feb',
    value: 55000,
  },
  {
    date: 'Mar',
    value: 45000,
  },
  {
    date: 'Apr',
    value: 60000,
  },
  {
    date: 'May',
    value: 58000,
  },
  {
    date: 'Jun',
    value: 65000,
  },
  {
    date: 'Jul',
    value: 50000,
  },
  {
    date: 'Aug',
    value: 55000,
  },
  {
    date: 'Sep',
    value: 45000,
  },
  {
    date: 'Oct',
    value: 60000,
  },
  {
    date: 'Nov',
    value: 58000,
  },
  {
    date: 'Dec',
    value: 65000,
  },
];

function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

const EquityChart = ({
  monthRange,
  statements,
}) => {

  const limitedData = statements.slice(data.length - monthRange);
  const displayData = limitedData.map((statement) => {

    const options = { year: 'numeric', month: 'long' };
    const date = statement.statementDate.toLocaleDateString('en-nz', options);

    return {
      date,
      returnOnEquity: (statement.netProfit / statement.totalEquity).toFixed(3),
      returnOnAsset: (statement.netProfit / statement.totalAssets).toFixed(3),
    }
  })

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <AreaChart
          width={500}
          height={300}
          data={displayData}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5850EC" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="#5850EC" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#999', fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#999', fontSize: 12 }}
            width={30}
            tickFormatter={tick => {
              return kFormatter(tick);
            }}
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="returnOnEquity"
            name="Return on Equity"
            stroke="#5850EC"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={{ r: 4, fill: '#5850EC', stroke: '#fff', strokeWidth: 2, }}
          />
          <Area
            type="monotone"
            dataKey="returnOnAsset"
            name="Return on Asset"
            stroke="#2196f3"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            fillOpacity={1}
            fill="url(#colorUv)"
            dot={{ r: 4, fill: '#2196f3', stroke: '#fff', strokeWidth: 2, }}
          />
          <Legend iconType="circle" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default EquityChart;
