import Parse from 'parse';

import { formatDate } from '../../../utils';

const fetchSyndicationHorse = async (syndicationId) => {
  const query = new Parse.Query('Syndication');
  query.include('horse');
  const syndication = await query.get(syndicationId);
  return syndication.get('horse');
}

const fetchNews = async (horseId) => {
  const Horse = Parse.Object.extend('Horse');
  const horse = new Horse();
  horse.id = horseId;
  const query = new Parse.Query('News');
  query.equalTo('relatedHorse', horse);
  query.descending('date');
  const news = await query.find();
  return news;
}

const getHorse = (horseObj) => {
  return {
    id: horseObj.id,
    horseName: horseObj.get('horseName'),
    serialNumber: horseObj.get('serialNumber'),
    gender: horseObj.get('gender'),
    dateOfBirth: formatDate(horseObj.get('dateOfBirth')),
    breedingStud: horseObj.get('breedingStud'),
    coatColor: horseObj.get('coatColor'),
    horseType: horseObj.get('horseType'),
    sire: horseObj.get('sire'),
    dam: horseObj.get('dam'),
    trainer: horseObj.get('trainer'),
    countryOfOrigin: horseObj.get('countryOfOrigin'),
    trainingPlace: horseObj.get('trainingPlace'),
    presentStatus: horseObj.get('presentStatus'),
    pedigreeURL: horseObj.get('pedigreeUrl'),
    imageURL: horseObj.get('image') ? horseObj.get('image').url() : '',
  };
}

const getNews = (newsObj) => {
  return {
    id: newsObj.id,
    date: formatDate(newsObj.get('date')),
    title: newsObj.get('title'),
    description: newsObj.get('description'),
    imageURL: newsObj.get('image') ? newsObj.get('image').url() : '',
    linkURL: newsObj.get('linkURL'),
  };
}

const fetchInitDataAction = async (syndicationId) => {

  try {
    const [ fetchedHorse, syndicationData ] = await Promise.all([
      fetchSyndicationHorse(syndicationId),
      Parse.Cloud.run('fetchSyndicationInitData', {syndicationId}),
    ]);

    const fetchedNews = await fetchNews(fetchedHorse.id);

    const horse = getHorse(fetchedHorse);
    const news = fetchedNews.map(getNews);
    const transactions = syndicationData.transactions;
    const totalBalances = syndicationData.syndication;
    const currentShare = syndicationData.shares[0].sharePercentage;

    const payload = {
      status: 'success',
      horse,
      news,
      transactions,
      totalBalances,
      currentShare,
    }

    return payload;
  } catch (error) {
    return {
      status: 'error',
    }
  }


}

export default fetchInitDataAction;
