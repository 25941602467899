/* eslint-disable react/no-multi-comp */
import React from 'react';
import {
  PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend
} from 'recharts';

const COLORS = [
  '#5850EC',
  '#2196f3',
  '#ff9800',
  '#4caf50',
  '#e53935',
];

const BalanceChart = ({
  data
}) => {

  return (
    <ResponsiveContainer width="100%" height={260}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          fill="#8884d8"
          innerRadius={80}
          labelLine={false}
          outerRadius={100}
          paddingAngle={0}
        >
          {
            data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Tooltip />
        <Legend iconType="circle" />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default BalanceChart;
