import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Parse from 'parse';

import { ThemeProvider } from '@material-ui/styles';

import Routes from './Routes';
import theme from './theme';

export const RouteContext = React.createContext();

export default function App() {

  Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL; // This is your Server URL
  Parse.initialize(
    process.env.REACT_APP_PARSE_APPLICATION_ID, // This is your Application ID
    process.env.REACT_APP_PARSE_JAVASCRIPT_KEY // This is your Javascript key
  );

  const [ currentRoute, setCurrentRoute ] = useState('investment');
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);

  useEffect(() => {

    const currentUser = Parse.User.current();

    if (!currentUser) {
      Parse.User.logOut();
      setIsAuthenticated(false);
      return;
    }

    setIsAuthenticated(true);
  }, []);

  const contextValue = {
    currentRoute,
    setCurrentRoute,
    isAuthenticated,
    setIsAuthenticated,
  }

  return (
    <ThemeProvider theme={theme}>
      <RouteContext.Provider value={contextValue}>
        <Router>
          <Routes isAuthenticated={isAuthenticated} />
        </Router>
      </RouteContext.Provider>
    </ThemeProvider>
  );
}
