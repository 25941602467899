import Parse from 'parse';

const getQueryDate = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const queryYear = currentMonth < 4 ? currentYear - 1 : currentYear;

  return new Date(queryYear, 4, 1);
}

async function fetchCompanyTransactions() {
  try {

    const fromDate = getQueryDate();

    const payload = await Parse.Cloud.run('fetchCompanyTransactions', {fromDate});
    return payload;

  } catch (error) {
    console.error('fetchCompanyTransactions error', error);
    return [];
  }
}

export default fetchCompanyTransactions;
