import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'k', 'm', 'b','t'];
    var suffixNum = Math.floor( (''+value).length/3 );
    var shortValue = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
      if (dotLessShortValue.length <= 2) { break; }
    }
    if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
    newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

const chartColors = [
  '#2196f3',
  '#5850EC',
  '#ff9800',
  '#4caf50',
  '#e53935',
];

const drawBars = (data) => {
  const dataSet = data[0];
  let areaArr = [];
  let count = 0;
  for(let i in dataSet) {
    if(Object.prototype.hasOwnProperty.call(dataSet, i) && i != 'date'){
      areaArr.push(
        <Bar
          key={`area-chart-${count}`}
          dataKey={i}
          fill={chartColors[count]}
          radius={10}
          maxBarSize={12}
        />
      );
      count++;
    }
  }
  return areaArr;
}

const MarketOverviewChart = ({data}) => {

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          barCategoryGap="12%"
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#999', fontSize: 12 }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#999', fontSize: 12 }}
            width={30}
            tickFormatter={tick => {
              return abbreviateNumber(tick);
            }}
          />
          <Tooltip />
          <Legend
            iconType="circle"
          />

          {drawBars(data)}

        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MarketOverviewChart;
