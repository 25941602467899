
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { routes } from '../../const';

import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';

import {
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons';

import {
  TransactionsTable,
} from './components';

import { Spinner } from '../../components';

const CompanyTransactions = ({
  expenseTransaction,
  incomeTransaction,
  isLoading,
  totalExpense,
  totalIncome,
}) => {

  return (
    <>
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        <>
          <Grid
            item
            xs={12}
          >
            <Grid item xs={12}>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
                style={{marginBottom: '16px'}}
              >
                <Link color="inherit">
                  Dashboard
                </Link>
                <Link
                  color="inherit"
                  component={RouterLink}
                  to={routes.INVESTMENT}
                >
                  Investment Dashboard
                </Link>
                <Typography color="textPrimary">
                  Company Transactions
                </Typography>
              </Breadcrumbs>
              <Typography variant="h3">
                Company Transactions
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <TransactionsTable
              data={incomeTransaction}
              title="Income"
              totalSum={totalIncome}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <TransactionsTable
              data={expenseTransaction}
              title="Expense"
              totalSum={totalExpense}
            />
          </Grid>
        </>
      }
    </>
  );
}

export default CompanyTransactions;
