import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import { ButtonWithLoading, Spinner, SubTitle, Title } from '../../components';

import { formatDate } from '../../utils';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
  backLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(3),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1) / -2,
  },
  formGroup: {
    marginBottom: theme.spacing(4),
  }
}));

const CustomerPage = ({
  credentials,
  credentialsFormHasError,
  credentialsFormRef,
  currentPasswordError,
  customer,
  customerFormHasError,
  customerFormRef,
  isLoading,
  isSubmitting,
  onDateFieldChange,
  onFieldChange,
  onPasswordFieldChange,
  onSubmit,
  onSubmitPassword,
}) => {

  const classes = useStyles();

  const getCurrentPasswordError = () => {

    const { currentPassword } = credentials;

    if (credentialsFormHasError && !currentPassword) {
      return 'This is a required field.';
    }

    if (currentPasswordError) {
      return 'The password you have entered does not match your current one';
    }

    return false;
  };

  const getConfirmNewPasswordError = () => {
    const { newPassword, confirmNewPassword } = credentials;

    if (credentialsFormHasError && !confirmNewPassword) {
      return 'This is a required field.'
    }

    if (credentialsFormHasError && newPassword !== confirmNewPassword) {
      return 'The new password and confirmation password does not match.'
    }

    return false;
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>My Details</Title>
      </Grid>
      <Grid item xs={12}>
        {isLoading ?
          <Spinner /> :
          <Paper
            className={classes.paper}
            ref={customerFormRef}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} className={classes.formGroup}>
                <SubTitle>Contact</SubTitle>
                <TextField
                  disabled={isSubmitting}
                  error={customerFormHasError && !customer.firstName}
                  fullWidth
                  helperText={customerFormHasError && !customer.firstName ? 'This is a required field' : null}
                  label="First Name"
                  margin="normal"
                  name="firstName"
                  onChange={onFieldChange}
                  required
                  value={customer.firstName}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Middle Name"
                  margin="normal"
                  name="middleName"
                  onChange={onFieldChange}
                  value={customer.middleName}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Last Name"
                  margin="normal"
                  name="lastName"
                  onChange={onFieldChange}
                  value={customer.lastName}
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disabled={isSubmitting}
                    disableToolbar
                    format="dd/MM/yyyy"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    label="Date of Birth"
                    margin="normal"
                    name="dateOfBirth"
                    onChange={onDateFieldChange}
                    value={formatDate(customer.dateOfBirth)}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Email"
                  margin="normal"
                  name="email"
                  onChange={onFieldChange}
                  value={customer.email}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Landline"
                  margin="normal"
                  name="landline"
                  onChange={onFieldChange}
                  value={customer.landline}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Mobile"
                  margin="normal"
                  name="mobile"
                  onChange={onFieldChange}
                  value={customer.mobile}
                />
              </Grid>
              <Grid item xs={12} md={6} className={classes.formGroup}>
                <SubTitle>Business</SubTitle>
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Business Name"
                  margin="normal"
                  name="businessName"
                  onChange={onFieldChange}
                  value={customer.businessName}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Job Title"
                  margin="normal"
                  name="jobTitle"
                  onChange={onFieldChange}
                  value={customer.jobTitle}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Invoice Title"
                  margin="normal"
                  name="invoiceTitle"
                  onChange={onFieldChange}
                  value={customer.invoiceTitle}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="Bank Account"
                  margin="normal"
                  name="bankAccount"
                  onChange={onFieldChange}
                  value={customer.bankAccount}
                />
                <TextField
                  disabled={isSubmitting}
                  fullWidth
                  label="GST Number"
                  margin="normal"
                  name="gstNumber"
                  onChange={onFieldChange}
                  value={customer.gstNumber}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonWithLoading
                color="primary"
                defaultLabel="Save changes"
                isLoading={isSubmitting}
                loadingLabel="Saving changes..."
                onClick={onSubmit}
                variant="contained"
              >
                Save Changes
              </ButtonWithLoading>
            </Grid>
          </Paper>
        }
      </Grid>
      <Grid item xs={12}>
        {isLoading ?
          <Spinner /> :
          <Paper
            className={classes.paper}
            ref={credentialsFormRef}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={6} className={classes.formGroup}>
                <SubTitle>Update Login Details</SubTitle>
                <TextField
                  disabled
                  fullWidth
                  helperText="If you'd like to change your login username, please contact our customer support."
                  label="Username"
                  margin="normal"
                  name="username"
                  value={credentials.username}
                />
                <TextField
                  error={getCurrentPasswordError()}
                  fullWidth
                  helperText={getCurrentPasswordError()}
                  label="Current Password"
                  margin="normal"
                  name="currentPassword"
                  onChange={onPasswordFieldChange}
                  required
                  type="password"
                  value={credentials.currentPassword}
                />
                <TextField
                  error={credentialsFormHasError && !credentials.newPassword}
                  fullWidth
                  helperText={credentialsFormHasError && !credentials.newPassword ? 'This is a required field' : null}
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onChange={onPasswordFieldChange}
                  required
                  type="password"
                  value={credentials.newPassword}
                />
                <TextField
                  error={getConfirmNewPasswordError()}
                  fullWidth
                  helperText={getConfirmNewPasswordError()}
                  label="Confirm New Password"
                  margin="normal"
                  name="confirmNewPassword"
                  onChange={onPasswordFieldChange}
                  required
                  type="password"
                  value={credentials.confirmNewPassword}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonWithLoading
                color="primary"
                defaultLabel="Save changes"
                isLoading={isSubmitting}
                loadingLabel="Saving changes..."
                onClick={onSubmitPassword}
                variant="contained"
              >
                Save Changes
              </ButtonWithLoading>
            </Grid>
          </Paper>
        }
      </Grid>
    </>
  );
}

export default CustomerPage;
