import React from 'react';
import currency from 'currency.js';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  totalBalance: {
    flex: 1,
  },
  balanceBreakdown: {
    display: 'flex',
  },
  breakdownItem: {
    flex: '1 0 auto',
  }
});

const Balance = ({ totalBalances }) => {

  const {
    income,
    deposit,
  } = totalBalances;

  const totalSum = income + deposit;

  const classes = useStyles();

  return (
    <React.Fragment>
      {/* TODO：Remove the 10% */}
      <div className={classes.totalBalance}>
        <Typography
          color="textSecondary"
          component="small"
          variant="overline"
        >
          Total balance
        </Typography>
        <Typography component="p" variant="h4">
          {currency(totalSum).format()}
        </Typography>
      </div>
      <div className={classes.balanceBreakdown}>
        <div className={classes.breakdownItem}>
          <Typography
            color="textSecondary"
            component="small"
            variant="overline"
          >
            Total Deposit
          </Typography>
          <Typography component="p" variant="h6">
            {currency(deposit).format()}
          </Typography>
        </div>
        <div className={classes.breakdownItem}>
          <Typography
            color="textSecondary"
            component="small"
            variant="overline"
          >
            Total Income
          </Typography>
          <Typography component="p" variant="h6">
            {currency(income).format()}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Balance;
