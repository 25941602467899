import Parse from 'parse';

const fetchInvestmentHorses = async() => {
  try {
    const query = new Parse.Query('InvestmentHorse');
    query.include('horse');
    query.include('investmentProduct');
    const investmentHorses = await query.find();

    const payload = investmentHorses.map((investmentHorse) => {

      const horse = investmentHorse.get('horse');
      const investmentProduct = investmentHorse.get('investmentProduct');

      return {
        id: investmentHorse.id,
        horseId: horse.id,
        investmentProductId: investmentProduct.id,
        horseName: horse.get('horseName'),
        gender: horse.get('gender'),
        horseType: horse.get('horseType'),
        horseValue: horse.get('horseValue'),
        investmentValue: horse.get('horseValue') * investmentHorse.get('shares') / 100,
        image: horse.get('image') && horse.get('image').url ? horse.get('image').url() : '',
        shares: investmentHorse.get('shares'),
      }

    });

    return payload;

  } catch (error) {
    console.error('fetchInvestmentHorses error', error);
    return [];
  }

}

export default fetchInvestmentHorses;
