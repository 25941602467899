import Parse from 'parse';

const fetchSyndicationsInitData = async () => {
  try {
    const currentUser = Parse.User.current();
    const payload = await Parse.Cloud.run('fetchCustomerSyndications', { userId: currentUser.id });
    return payload;
  } catch (error) {
    console.error('fetchSyndicationsInitData error', error);
  }
}

export default fetchSyndicationsInitData;
