import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import { routes } from '../../const';

import currency from 'currency.js';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 345,
    height: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  horseType: {
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 200,
  },
}));

const HorseCard = ({ data }) => {

  const {
    id,
    horseName,
    image,
    horseValue,
    investmentValue,
    shares,
    horseType,
  } = data;

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={image}
        title="Contemplative Reptile"
      />
      <CardContent className={classes.content}>
        <Chip
          className={classes.horseType}
          label={horseType}
          size="small"
        />
        <Typography
          component="h3"
          gutterBottom
          variant="subtitle2"
        >
          {horseName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Horse Value: {currency(horseValue).format()}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Investment Shares: {shares}%
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Investment Value: {currency(investmentValue).format()}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to={`${routes.INVESTMENT_HORSE}/${id}`}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default HorseCard;
