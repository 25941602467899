import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Copyright } from '../../components';
import { Topbar, Sidebar } from './components';
import useStyles from './styles';

import { signOutAction } from './actions';

import { RouteContext } from '../../App';

const Main = ({ children }) => {

  const classes = useStyles();

  const { setIsAuthenticated } = useContext(RouteContext);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isSigningOut, setIsSigningOut] = React.useState(false);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSignOut = async () => {
    setIsSigningOut(true);
    await signOutAction();
    setIsSigningOut(false);
    setIsAuthenticated(false);
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Topbar
        handleDrawerToggle={handleDrawerToggle}
      />
      <Sidebar
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        onSignOut={handleSignOut}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={classes.container}
          maxWidth="xl"
        >
          <Grid
            container
            spacing={3}
          >
            {children}
          </Grid>
        </Container>
        <Copyright />
      </main>
    </div>
  );
}

Main.propTypes = {
  children: PropTypes.element,
};

export default Main;
