import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SyndicationHorseDetails from './SyndicationHorseDetails';

import { fetchInitDataAction } from './actions';

import { RouteContext } from '../../App';

const SyndicationHorseDetailsContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);
  const { syndicationId } = useParams();

  const [ currentShare, setCurrentShare ] = useState(0);
  const [ totalBalances, setTotalBalances ] = useState({
    totalBalance: 0,
    depositBalance: 0,
    incomeBalance: 0,
    totalPrizeMoneyBalance: 0,
  });
  const [ horse, setHorse ] = useState({});
  const [ news, setNews ] = useState([]);
  const [ transactions, setTransactions ] = useState([]);

  const [ isLoading, setIsLoading ] = useState(true);

  const fetchInitData = async () => {
    setIsLoading(true);
    const {horse, news, transactions, totalBalances, currentShare} = await fetchInitDataAction(syndicationId);
    setHorse(horse);
    setNews(news);
    setTransactions(transactions);
    setTotalBalances(totalBalances);
    setCurrentShare(currentShare);
    setIsLoading(false);
  }

  useEffect(() => {

    setCurrentRoute('syndications');

    fetchInitData();

  }, []);

  return (
    <SyndicationHorseDetails
      currentShare={currentShare}
      horse={horse}
      isLoading={isLoading}
      news={news}
      totalBalances={totalBalances}
      transactions={transactions}
    />
  );
}

export default SyndicationHorseDetailsContainer;
