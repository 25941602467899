/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MaterialTable from 'material-table';

import currency from 'currency.js';

import {
  Spinner,
} from '../../../../components';

import { routes } from '../../../../const';

const useStyles = makeStyles({
  action: {
    alignSelf: 'center',
    marginTop: 0,
  },
});

const TransactionsTable = ({
  data,
  isLoading,
  title,
  totalSum,
}) => {

  const classes = useStyles();

  const columns = [
    {
      title: 'Date',
      field: 'date',
      type: 'date',
    },
    {
      title: 'Horse',
      field: 'horseName',
      editable: 'never',
      render: ({ horseId, horseName, investmentHorseId, sharePercentage }) => {
        let name = horseName;
        if (sharePercentage) name = horseName + ` (${sharePercentage}%)`;
        return <>
          {(horseId && horseName) ?
            <Link
              component={RouterLink}
              to={`${routes.INVESTMENT_HORSE}/${investmentHorseId}`}
            >
              {name}
            </Link> : null
          }
        </>
      },
    },
    {
      title: 'Amount',
      field: 'amount',
      type: 'currency',
      align: 'left',
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Invoice',
      field: 'invoiceUrl',
      headerStyle: {textAlign: 'right'},
      cellStyle: {paddingTop: 0, paddingBottom: 0, textAlign: 'right'},
      render: ({ invoiceUrl }) => (
        <>
          {invoiceUrl ?
            <IconButton
              component={Link}
              href={invoiceUrl}
              rel="noopener"
              target="_blank"
            >
              <SaveAltIcon />
            </IconButton> :
            null
          }
        </>
      )
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          action={<Typography>Total: {currency(totalSum).format()}</Typography>}
          classes={{
            action: classes.action,
          }}
          title={title}
        />
        <Divider />
        {isLoading ?
          <Spinner /> :
          <CardContent style={{ padding: 0, paddingBottom: 0 }}>

            {/* Table */}
            <MaterialTable
              columns={columns}
              components={{
                Container: props => <div>{props.children}</div>,
              }}
              data={data}
              options={{
                actionsColumnIndex: -1,
                search: false,
                paging: false,
                draggable: false,
                showTitle: false,
                toolbar: false,
              }}
            />

          </CardContent>
        }
      </Card>
    </>
  );
}

export default TransactionsTable;
