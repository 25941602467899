/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend
} from 'recharts';
import { Typography } from '@material-ui/core';

const COLORS = [
  '#5850EC',
  '#2196f3',
  '#ff9800',
  '#4caf50',
  '#e53935',
];


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ShareChart = ({
  horses
}) => {

  let displayData = [
    {
      name: 'Breeding',
      value: 0,
    },
    {
      name: 'Trade',
      value: 0,
    },
    {
      name: 'Race',
      value: 0,
    },
  ]

  horses.forEach((horse) => {
    switch (horse.horseType) {
      case 'Breeding':
        displayData[0].value += horse.investmentValue;
        break;
      case 'Trade':
        displayData[1].value += horse.investmentValue;
        break;
      case 'Race':
        displayData[2].value += horse.investmentValue;
        break;
      default:
        break;
    }
  });

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={displayData}
          labelLine={false}
          innerRadius={80}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          paddingAngle={0}
        >
          {
            displayData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Tooltip />
        <Legend iconType="circle" />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ShareChart;
