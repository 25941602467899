import Parse from 'parse';

const getCurrentCustomer = async () => {
  try {
    const currentUser = Parse.User.current();
    const query = new Parse.Query('Customer');
    query.equalTo('user', currentUser);
    const response = await query.first();
    return response;
  } catch (error) {
    console.error('getCurrentCustomer error', error);
    return { code: 400 }
  }

}

const getInvestmentCustomer = async (customer) => {
  try {
    const query = new Parse.Query('InvestmentCustomer');
    query.equalTo('customer', customer);
    const response = await query.first();
    return response;
  } catch (error) {
    console.error('getInvestmentCustomer error', error);
    return { code: 400 }
  }
}

const getInvestmentCustomerSummary = async (investmentCustomer) => {
  try {
    const query = new Parse.Query('InvestmentCustomerSummary');
    query.equalTo('investmentCustomer', investmentCustomer);
    const response = await query.first();
    return response;
  } catch (error) {
    console.error('getInvestmentCustomer error', error);
    return { code: 400 }
  }
}

const fetchInvestmentDetails = async () => {
  try {
    const customer = await getCurrentCustomer();
    const investmentCustomer = await getInvestmentCustomer(customer);

    if (!investmentCustomer || !investmentCustomer.get) {
      throw new Error('Permission denied');
    }

    const summary = await getInvestmentCustomerSummary(investmentCustomer);

    const payload = {
      joinedDate: investmentCustomer.get('joinedDate'),
      shares: investmentCustomer.get('shares'),
      totalIncome: summary.get('totalIncome') || 0,
      totalExpense: summary.get('totalExpense') || 0,
    }
    return payload;
  } catch (error) {
    console.error('fetchInvestmentDetails error', error);
    return { code: 400 }
  }
}

export default fetchInvestmentDetails;
