import React from 'react';
import Typography from '@material-ui/core/Typography';

const LabeledData = ({ label, value }) => {
  return (
    <div>
      <Typography
        color="textSecondary"
        component="small"
        variant="overline"
      >
        {label}
      </Typography>
      <Typography
        component="p"
      >
        {value}
      </Typography>
    </div>
  );
}

export default LabeledData;
