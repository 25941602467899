import React, { useContext, useState } from 'react';

import SignIn from './SignIn';

import { RouteContext } from '../../App';

// Actions
import { signInAction } from './actions';


const SignInContainer = () => {

  const { setIsAuthenticated } = useContext(RouteContext);

  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })

  const [authenticationError, setAuthenticationError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFieldChange = (event) => {
    const newState = {
      ...credentials,
      [event.target.name]: event.target.value
    }
    setCredentials(newState);
  }

  const validateForm = () => {
    const { username, password } = credentials;

    if (!username.trim() || !password) {
      setFormError(true);
      return false;
    }

    return true;
  }

  async function handleSubmit(event) {

    event.preventDefault();

    setFormError(false);
    setAuthenticationError(false);

    if (!validateForm()) return;

    setIsSubmitting(true);

    const authResult = await signInAction({
      username: credentials.username.trim(),
      password: credentials.password
    });

    setIsSubmitting(false);

    if (!authResult) {
      setAuthenticationError(true);
      return;
    }

    setFormError(false);
    setAuthenticationError(false);
    setIsAuthenticated(true);
  }

  return (
    <SignIn
      authenticationError={authenticationError}
      credentials={credentials}
      formError={formError}
      isSubmitting={isSubmitting}
      onFieldChange={handleFieldChange}
      onSubmit={handleSubmit}
    />
  );
}

export default SignInContainer;
