import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { InvestmentHorseCard } from '../../components';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {
  Breadcrumbs,
  Divider,
  FormControl,
  Link,
  MenuItem,
  Select,
  Paper,
} from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';

import {
  CurrentBalance,
  EquityChart,
  InvestmentAreaTable,
  InvestmentReport,
  InvestmentSplitChart,
  MarketOverviewChart,
  ShareChart,
} from './components';

import {
  Spinner,
} from '../../components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  demoBanner: {
    padding: theme.spacing(2),
    backgroundColor: '#ffd54f',
  },
  investmentAreaContent: {
    padding: 0,
    paddingBottom: '0!important',
    [theme.breakpoints.up('md')]: {
      minHeight: '340px',
    }
  },
}));

const SyndicationDashboard = ({
  bettingData,
  breedingHorses,
  broodmareData,
  foalData,
  horses,
  investmentDetails,
  isError,
  isLoading,
  prizeMoneyData,
  raceHorses,
  rtrData,
  soldHorses,
  statement,
  statements,
  summary,
  tradeHorses,
  weanlingData,
  yearlingData,
}) => {

  const classes = useStyles();

  const [ equityChartState, setEquityChartState ] = useState(12);

  const handleEquityMonthRangeChange = (event) => {
    setEquityChartState(event.target.value);
  }

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 60);

  const breedingRef = useRef(null);
  const tradeRef = useRef(null);
  const raceRef = useRef(null);


  const handleInvestmentTypeClick = (type) => {
    switch (type) {
      case 'Breeding':
        scrollToRef(breedingRef);
        break;
      case 'Trade':
        scrollToRef(tradeRef);
        break;
      case 'Race':
        scrollToRef(raceRef);
        break;
      default:
        break;
    }
  }

  return (
    <>

      {/* Page Title */}
      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        isError ?
          <Grid item xs={12}>
            <Paper className={classes.demoBanner}>
              <Typography variant="h5">Investment Membership (From $50,000)</Typography>
              <Typography component="ul" variant="body2">
                <li>Buy into club equity and enjoy the exclusive membership benefits</li>
                <li>Risk diversification way to invest in the lucrative bloodstock industry</li>
                <li>24/7/365 on track of all recent club investment activities from your membership portal</li>
                <li>Professionally managed by a team of skilled industry experts</li>
              </Typography>
            </Paper>
          </Grid> :
          <>
            <Grid item xs={12}>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
                style={{marginBottom: '16px'}}
              >
                <Link color="inherit">
                  Dashboard
                </Link>
                <Typography color="textPrimary">
                  Investment Report
                </Typography>
              </Breadcrumbs>
              <Typography variant="h3">
                Here's what's happening
              </Typography>
            </Grid>

            {/* Balance */}
            <Grid item xs={12}>
              <CurrentBalance summary={summary} />
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader
                  title="My Share"
                />
                <Divider />
                <CardContent>
                  <ShareChart
                    summary={summary}
                    investmentDetails={investmentDetails}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Balance */}
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  title="Equity Performance"
                  action={
                    <FormControl className={classes.formControl}>
                      <Select
                        value={equityChartState}
                        onChange={handleEquityMonthRangeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value={3}>3 Months</MenuItem>
                        <MenuItem value={6}>6 Months</MenuItem>
                        <MenuItem value={12}>12 Months</MenuItem>
                      </Select>
                    </FormControl>
                  }
                />
                <Divider />
                <CardContent>
                  <EquityChart monthRange={equityChartState} statements={statements} />
                </CardContent>
              </Card>
            </Grid>

            {/* Balance */}
            <Grid item xs={12}>
              <InvestmentReport statement={statement} />
            </Grid>

            {/* Balance */}
            <Grid item xs={12} md={8}>
              <Card>
                <CardHeader
                  title="Investment Area"
                />
                <Divider />
                <CardContent classes={{ root: classes.investmentAreaContent }}>
                  <InvestmentAreaTable
                    horses={horses}
                    breedingHorses={breedingHorses}
                    tradeHorses={tradeHorses}
                    raceHorses={raceHorses}
                    onInvestmentTypeClick={handleInvestmentTypeClick}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card>
                <CardHeader
                  title="Investment Split"
                />
                <Divider />
                <CardContent>
                  <InvestmentSplitChart
                    horses={horses}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Page Title */}
            <Grid item xs={12}>
              <Typography variant="h3">
                Market Overview
              </Typography>
            </Grid>

            {/* Foal */}
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardHeader
                  title="Foal"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={foalData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Betting */}
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardHeader
                  title="Betting"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={bettingData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Prize Money */}
            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <CardHeader
                  title="Prize Money"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={prizeMoneyData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Section Title */}
            <Grid item xs={12}>
              <Typography variant="h3">
                Australasian Sales Statistics (NZ & AUS)
              </Typography>
            </Grid>

            {/* Weanling */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardHeader
                  title="Weanling"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={weanlingData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Yearling */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardHeader
                  title="Yearling"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={yearlingData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* 2-Y-O(RTR) */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardHeader
                  title="2-Y-O(RTR)"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={rtrData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Broodmare */}
            <Grid item xs={12} md={6} lg={3}>
              <Card>
                <CardHeader
                  title="Broodmare"
                />
                <Divider />
                <CardContent>
                  <MarketOverviewChart
                    data={broodmareData}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3">
                Invested Horses
              </Typography>
            </Grid>

            {breedingHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  ref={breedingRef}
                  xs={12}
                >
                  <Typography variant="h5">
                    Breeding
                  </Typography>
                </Grid>

                {/* Horse Title */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {breedingHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.horseId}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <InvestmentHorseCard
                          data={horse}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {tradeHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  ref={tradeRef}
                  xs={12}
                >
                  <Typography variant="h5">
                    Trade
                  </Typography>
                </Grid>

                {/* Horse Title */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {tradeHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.horseId}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <InvestmentHorseCard
                          data={horse}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {raceHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  ref={raceRef}
                  xs={12}
                >
                  <Typography variant="h5">
                    Race
                  </Typography>
                </Grid>

                {/* Horse Title */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {raceHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.horseId}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <InvestmentHorseCard
                          data={horse}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {soldHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  ref={raceRef}
                  xs={12}
                >
                  <Typography variant="h5">
                    Sold
                  </Typography>
                </Grid>

                {/* Horse Title */}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {soldHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.horseId}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <InvestmentHorseCard
                          data={horse}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }
          </>
      }
    </>
  );
}

export default SyndicationDashboard;
