import Parse from 'parse';

const fetchCurrentCustomerAction = async () => {
  const currentUser = Parse.User.current();
  const customerQuery = new Parse.Query('Customer');
  customerQuery.equalTo('user', currentUser);
  const currentCustomer = await customerQuery.first();
  return currentCustomer;
}

export default fetchCurrentCustomerAction;
