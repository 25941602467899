import Parse from 'parse';

const updateCustomerAction = async ({
  id,
  firstName,
  middleName,
  lastName,
  dateOfBirth,
  landline,
  mobile,
  email,
  businessName,
  jobTitle,
  invoiceTitle,
  bankAccount,
  gstNumber,
}) => {

  try {

    const Customer = Parse.Object.extend('Customer');
    const customer = new Customer();

    customer.id = id;
    customer.set('firstName', firstName);
    customer.set('middleName', middleName);
    customer.set('lastName', lastName);
    customer.set('dateOfBirth', dateOfBirth);
    customer.set('landline', landline);
    customer.set('mobile', mobile);
    customer.set('email', email);
    customer.set('businessName', businessName);
    customer.set('jobTitle', jobTitle);
    customer.set('invoiceTitle', invoiceTitle);
    customer.set('bankAccount', bankAccount);
    customer.set('gstNumber', gstNumber);

    const result = await customer.save();

    return result;

  } catch (error) {
    return error;
  }
}

export default updateCustomerAction;
