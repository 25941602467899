import Parse from 'parse';

const updateUserPasswordAction = async ({
  currentPassword,
  confirmNewPassword,
}) => {

  try {

    const user = Parse.User.current();

    const oldPassword = user.get('password');

    if (oldPassword !== currentPassword) {
      throw new Error('notMatch');
    }

    user.set('password', confirmNewPassword);

    const result = await user.save();

    return {
      success: true,
      status: 'success',
      result
    }

  } catch (error) {
    return {
      success: false,
      status: error.message
    };
  }
}

export default updateUserPasswordAction;
