import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import InvestmentHorse from './InvestmentHorse';

import {
  fetchInvestmentHorseInitData,
} from './actions';

import { RouteContext } from '../../App';

const InvestmentHorseContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);
  const { investmentHorseId } = useParams();

  const [ horse, setHorse ] = useState({});
  const [ news, setNews ] = useState([]);
  const [ transactions, setTransactions ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  const _initData = async () => {
    setIsLoading(true);

    const data = await fetchInvestmentHorseInitData(investmentHorseId);

    setHorse(data.investmentHorse);
    setTransactions(data.horseTransactions);
    setNews(data.horseNews);
    setIsLoading(false);
  }

  useEffect(() => {

    setCurrentRoute('investment');

    _initData();

  }, []);

  return (
    <InvestmentHorse
      horse={horse}
      isLoading={isLoading}
      news={news}
      transactions={transactions}
    />
  );
}

export default InvestmentHorseContainer;
