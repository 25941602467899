import React from 'react';
import currency from 'currency.js';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: theme.spacing(2),
  },
  creditAmount: {
    color: '#e57373',
  },
  debitAmount: {
    color: '#4caf50',
  }
}));

const HorseDataWidget = ({
  format,
  title,
  value
}) => {

  const classes = useStyles();

  const displayValue = format === 'currency' ? `${currency(value).format()}` : value;

  const getCurrencyClasses = () => {
    if (format !== 'currency' || value === 0) return;
    return value > 0 ? classes.debitAmount : classes.creditAmount;
  }

  return (
    <Paper className={classes.paper}>
      <Typography
        color="textSecondary"
        component="p"
        variant="overline"
      >
        {title}
      </Typography>
      <Typography
        className={getCurrencyClasses()}
        component="p"
        variant="h5"
      >
        {displayValue}
      </Typography>
    </Paper>
  );
}

export default HorseDataWidget;
