import React from 'react';

// Material UI
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Typography from '@material-ui/core/Typography';

// Shared components
import { ButtonWithLoading, Copyright } from '../../components';

// Styles
import useStyles from './styles';

const SignIn = ({
  authenticationError,
  credentials,
  formError,
  isSubmitting,
  onFieldChange,
  onSubmit
}) => {
  const classes = useStyles();

  const {
    username,
    password,
  } = credentials;

  const validateUserName = () => {
    if (formError && !username.trim()) {
      return {
        error: true,
        label: 'This is a required field'
      }
    }

    if (authenticationError) {
      return {
        error: true,
        label: null,
      }
    }

    return {
      error: false,
      label: null,
    };
  }

  const validatePassword = () => {
    if (formError && !password) {
      return {
        error: true,
        label: 'This is a required field'
      }
    }

    if (authenticationError) {
      return {
        error: true,
        label: null,
      }
    }

    return {
      error: false,
      label: null,
    };
  }

  return (
    <Grid
      className={classes.root}
      component="main"
      container
    >
      <CssBaseline />
      <Grid
        className={classes.image}
        item
        md={7}
        sm={4}
        xs={false}
      />
      <Grid
        component={Paper}
        elevation={6}
        item
        md={5}
        sm={8}
        square
        xs={12}
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
          >
            Sign in
          </Typography>
          <div className={classes.form}>
            {authenticationError ?
              <SnackbarContent
                aria-describedby="client-snackbar"
                className={classes.errorBar}
                message="Login failed. Invalid user ID or password. Please try again."
              /> :
              null
            }
            <TextField
              error={validateUserName().error}
              fullWidth
              helperText={validateUserName().label}
              id="email"
              label="Email Address"
              margin="normal"
              name="username"
              onChange={onFieldChange}
              required
              value={username}
              variant="outlined"
            />
            <TextField
              error={validatePassword().error}
              fullWidth
              helperText={validatePassword().label}
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={onFieldChange}
              required
              type="password"
              value={password}
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  value="remember"
                />
              }
              label="Remember me"
            />
            <ButtonWithLoading
              className={classes.submit}
              color="primary"
              defaultLabel="Sign In"
              fullWidth
              isLoading={isSubmitting}
              loadingLabel="Sining In..."
              onClick={onSubmit}
              variant="contained"
            />
            <Grid container>
              <Grid
                item
                xs
              >
                <Link
                  href="#"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default SignIn;
