import React from 'react';

import Grid from '@material-ui/core/Grid';

import { NewsCard, Title } from '../../../../components';

const RelatedNews = ({
  news
}) => {

  const newsList = news.map((newsItem) => {

    return (
      <Grid
        item
        key={newsItem.id}
        lg={3}
        md={4}
        xs={12}
      >
        <NewsCard
          news={newsItem}
        />
      </Grid>
    );
  })

  return (
    <>
      {newsList && newsList.length > 0 ?
        <Grid
          item
          xs={12}
        >
          <Title>Related News</Title>
          <Grid
            container
            spacing={3}
          >
            {newsList}
          </Grid>
        </Grid> :
        null
      }
    </>
  );
}

export default RelatedNews;
