import React from 'react';

import {
  Typography
} from '@material-ui/core';

import MaterialTable from 'material-table';
import currency from 'currency.js';

export default function CustomerHorseSharesTable({
  horses,
  breedingHorses,
  tradeHorses,
  raceHorses,
  onInvestmentTypeClick
}) {

  const rawDisplayData = [
    {
      type: 'Breeding',
      length: '3 years',
      number: breedingHorses.length,
      totalInvestmentValue: breedingHorses.reduce((a, b) => (a + b.investmentValue), 0),
    },
    {
      type: 'Trade',
      length: '4 to 10 months',
      number: tradeHorses.length,
      totalInvestmentValue: tradeHorses.reduce((a, b) => (a + b.investmentValue), 0),
    },
    {
      type: 'Race',
      length: '2 to 5 years',
      number: raceHorses.length,
      totalInvestmentValue: raceHorses.reduce((a, b) => (a + b.investmentValue), 0),
    },
  ];

  const displayData = rawDisplayData.filter((data) => data.number > 0);

  const columns = [
    {
      title: 'Type',
      field: 'investmentType',
      type: 'string',
      render: ({type}) => (
        <a
          onClick={() => onInvestmentTypeClick(type)}
          style={{color: '#5850EC', cursor: 'pointer'}}
        >
          <strong>{type}</strong>
        </a>
      )
    },
    { title: 'Length', field: 'length', type: 'string' },
    {
      title: 'Total value',
      field: 'totalInvestmentValue',
      render: ({ totalInvestmentValue }) => currency(totalInvestmentValue, { formatWithSymbol: true }).format(),
    },
    { title: 'Number of horses', field: 'number', type: 'numeric' },
  ];

  return (
    <>
      <MaterialTable
        components={{
          Container: props => <div>{props.children}</div>,
          //Container: React.Fragment
        }}
        columns={columns}
        data={displayData}
        options={{
          actionsColumnIndex: -1,
          search: false,
          paging: false,
          draggable: false,
          showTitle: false,
          toolbar: false,
        }}
        title="Transactions"
      />
      <Typography
        style={{padding: 16, textAlign: 'right'}}
      >
        Total horses: <strong>{horses.length}</strong>
      </Typography>
    </>
  );
}
