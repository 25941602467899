import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import currency from 'currency.js';

import { routes } from '../../../../const';

import {
  Link,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  gridItem: {
    textAlign: 'center',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(3)': {
        borderBottom: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        borderBottom: 0,
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  }
}));

const CurrentBalance = ({
  summary
}) => {

  const classes = useStyles();

  const returnDate = new Date('1-1-2024').toLocaleDateString('en');

  return(
    <Paper>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={classes.gridItem}
        >
          <Typography
            color="textSecondary"
            variant="overline"
            style={{display: 'block'}}
          >
            Total Assets
          </Typography>
          <Typography
            variant="h3"
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
            }}
          >
            {currency(summary.totalAssets).format()}
          </Typography>
          {/* <span
            style={{
              display: 'inline-block',
              verticalAlign: 'middle',
              marginLeft: '8px',
              padding: '4px 8px',
              backgroundColor: 'rgba(76, 175, 80, 0.08)',
              color: '#4caf50',
              fontSize: '0.75rem',
              fontWeight: 500,
              lineHeight: 1,
            }}
          >
            +20%
          </span> */}
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={classes.gridItem}
        >
          <Typography
            color="textSecondary"
            variant="overline"
            style={{display: 'block'}}
          >
            Total Income
          </Typography>
          <Typography variant="h3">
            <Link
              component={RouterLink}
              to={routes.COMPANY_TRANSACTIONS}
            >
              {currency(summary.totalIncome).format()}
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={classes.gridItem}
        >
          <Typography
            color="textSecondary"
            variant="overline"
            style={{display: 'block'}}
          >
            Total Expense
          </Typography>
          <Typography variant="h3">
            <Link
              component={RouterLink}
              to={routes.COMPANY_TRANSACTIONS}
            >
              {currency(summary.totalExpense).format()}
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          className={classes.gridItem}
        >
          <Typography
            color="textSecondary"
            variant="overline"
            style={{display: 'block'}}
          >
            Next Dividend Date
          </Typography>
          <Typography variant="h3">
            {summary.dividendDate}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default CurrentBalance;
