import React, { useContext, useEffect, useRef, useState } from 'react';

import UserDetails from './UserDetails';

import { fetchInitDataAction, updateCustomerAction, updateUserPasswordAction } from './actions';

import { RouteContext } from '../../App';

const UserDetailsContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ customer , setCustomer ] = useState({});
  const [ credentials , setCredentials ] = useState({});
  const [ isLoading , setIsLoading ] = useState(true);
  const [ isSubmitting , setIsSubmitting ] = useState(false);
  const [ customerFormHasError , setCustomerFormHasError ] = useState(false);
  const [ credentialsFormHasError , setCredentialsFormHasError ] = useState(false);
  const [ currentPasswordError , setCurrentPasswordError ] = useState(false);

  useEffect(() => {
    setCurrentRoute('details');
    fetchInitData();
  }, []);

  const fetchInitData = async () => {
    setIsLoading(true);
    const { credentials, customer } = await fetchInitDataAction();
    setCredentials(credentials);
    setCustomer(customer);
    setIsLoading(false);
  }

  const handleFieldChange = (event) => {
    const newState = {
      ...customer,
      [event.target.name]: event.target.value
    }
    setCustomer(newState);
  }

  const handleDateFieldChange = (dateString) => {
    const newState = {
      ...customer,
      dateOfBirth: dateString
    }
    setCustomer(newState);
  }

  const validateCustomerForm = () => {

    if (!customer.firstName) {
      setCustomerFormHasError(true);
      scrollToRef(customerFormRef);
      return false;
    }

    setCustomerFormHasError(false);
    return true;
  }

  const handleSubmit = async () => {

    if (!validateCustomerForm()) return;

    setIsSubmitting(true);
    await updateCustomerAction(customer);
    setIsSubmitting(false);
  }

  const handlePasswordFieldChange = (event) => {
    const newState = {
      ...credentials,
      [event.target.name]: event.target.value
    }
    setCredentials(newState);
  }

  const validateCredentialsForm = () => {

    const { currentPassword, newPassword, confirmNewPassword } = credentials;

    if (!currentPassword || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword) {
      setCredentialsFormHasError(true);
      scrollToRef(credentialsFormRef);
      return false;
    }

    return true;
  }

  const handleSubmitPassword = async () => {

    setCredentialsFormHasError(false);
    setCurrentPasswordError(false);

    if (!validateCredentialsForm()) return;

    setIsSubmitting(true);

    const result = await updateUserPasswordAction(credentials);

    setIsSubmitting(false);

    if (!result.success) {
      setCurrentPasswordError(true);
      return;
    }

    setCredentials({
      ...credentials,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    })

  }

  const customerFormRef = useRef(null);
  const credentialsFormRef = useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView();
  }

  return (
    <UserDetails
      credentials={credentials}
      credentialsFormHasError={credentialsFormHasError}
      credentialsFormRef={credentialsFormRef}
      currentPasswordError={currentPasswordError}
      customer={customer}
      customerFormHasError={customerFormHasError}
      customerFormRef={customerFormRef}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      onDateFieldChange={handleDateFieldChange}
      onFieldChange={handleFieldChange}
      onPasswordFieldChange={handlePasswordFieldChange}
      onSubmit={handleSubmit}
      onSubmitPassword={handleSubmitPassword}
    />
  );
}

export default UserDetailsContainer;
