import React from 'react';
import clsx from 'clsx';
import currency from 'currency.js';

import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';

import { HorseCard, Spinner, Title } from '../../components';

import { BalanceChart } from './components';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  }
}));

const BalanceWidget = ({
  title,
  value,
}) => {
  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardHeader
          title={title}
        />
        <CardContent
          style={{ minHeight: 112, display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant="h2"
          >
            {currency(value).format()}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

const SyndicationDashboard = ({
  breedingHorses,
  chartData,
  isError,
  isLoading,
  raceHorses,
  soldHorses,
  totalBalances,
  tradeHorses,
}) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      {/* Page Title */}
      <Grid item xs={12}>
        <Title>My Syndications Report</Title>
      </Grid>

      {isLoading ?
        <Grid item xs={12}>
          <Spinner />
        </Grid> :
        isError ?
          <Grid item xs={12}>
            <MuiAlert
              elevation={6}
              variant="filled"
              severity="warning"
            >
              You have no permission to view this page
            </MuiAlert>
          </Grid> :
          <>
            {/* Balance */}
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={3}
              >
                <BalanceWidget
                  title="Total Balance"
                  value={totalBalances.totalSum}
                />
                <BalanceWidget
                  title="Total Deposit"
                  value={totalBalances.deposit}
                />
                <BalanceWidget
                  title="Total Income"
                  value={totalBalances.income}
                />
                <BalanceWidget
                  title="Total Prize Money"
                  value={totalBalances.prizeMoney}
                />
              </Grid>
            </Grid>

            {/* Chart */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  title="Balance Chart"
                />
                <Divider />
                <CardContent>
                  <BalanceChart
                    data={chartData}
                  />
                </CardContent>
              </Card>
            </Grid>

            {/* Breeding Horses */}
            {breedingHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h5">
                    Breeding
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {breedingHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.id}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <HorseCard
                          horseType={horse.horseType}
                          id={horse.id}
                          imageUrl={horse.imageURL}
                          name={horse.horseName}
                          shares={horse.sharePercentage}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {/* Sold Horses */}
            {tradeHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h5">
                    Trade
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {tradeHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.id}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <HorseCard
                          horseType={horse.horseType}
                          id={horse.id}
                          imageUrl={horse.imageURL}
                          name={horse.horseName}
                          shares={horse.sharePercentage}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {/* Race Horses */}
            {raceHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h5">
                    Race
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {raceHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.id}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <HorseCard
                          horseType={horse.horseType}
                          id={horse.id}
                          imageUrl={horse.imageURL}
                          name={horse.horseName}
                          shares={horse.sharePercentage}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

            {/* Sold Horses */}
            {soldHorses.length < 1 ? null :
              <>
                <Grid
                  item
                  xs={12}
                >
                  <Typography variant="h5">
                    Sold
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {soldHorses.map((horse) => (
                      <Grid
                        item
                        key={horse.id}
                        lg={3}
                        md={4}
                        xs={12}
                      >
                        <HorseCard
                          horseType={horse.horseType}
                          id={horse.id}
                          imageUrl={horse.imageURL}
                          name={horse.horseName}
                          shares={horse.sharePercentage}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </>
            }

          </>
      }
    </>
  );
}

export default SyndicationDashboard;
