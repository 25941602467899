import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  media: {
    height: 200,
  },
});

const NewsCard = ({ news }) => {
  const classes = useStyles();

  const {
    title,
    description,
    imageURL,
    linkURL,
  } = news;

  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={imageURL}
          title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          color="primary"
          component={Link}
          href={linkURL}
          rel="noopener"
          size="small"
          target="_blank"
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export default NewsCard;
