import React, { useContext, useState, useEffect } from 'react';

import SyndicationDashboard from './SyndicationDashboard';

import { fetchSyndicationsInitData } from './actions';

import { RouteContext } from '../../App';

const SyndicationDashboardContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [ totalBalances, setTotalBalances ] = useState({});
  const [ chartData, setChartdata ] = useState([]);
  const [ isError, setIsError ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);

  const [breedingHorses, setBreedingHorses] = useState([]);
  const [tradeHorses, setTradeHorses] = useState([]);
  const [raceHorses, setRaceHorses] = useState([]);
  const [soldHorses, setSoldHorses] = useState([]);

  const fetchInitData = async () => {
    setIsLoading(true);
    const result = await fetchSyndicationsInitData();

    if (!result || !result.horses || result.horses.length <= 0 ) {
      setIsError(true);
    } else {
      _splitHorses(result.horses);
      setTotalBalances(result.totalBalances);
      setChartdata(result.chartData);
    }

    setIsLoading(false);
  }

  const _splitHorses = (horses) => {
    let breedingHorses = [];
    let tradeHorses = [];
    let raceHorses = [];
    let soldHorses = [];

    horses.forEach((horse) => {
      switch (horse.horseType) {
        case 'Breeding':
          breedingHorses.push(horse);
          break;
        case 'Trade':
          tradeHorses.push(horse);
          break;
        case 'Race':
          raceHorses.push(horse);
          break;
        case 'Sold':
          soldHorses.push(horse);
          break;
        default:
          break;
      }
    });

    setBreedingHorses(breedingHorses);
    setTradeHorses(tradeHorses);
    setRaceHorses(raceHorses);
    setSoldHorses(soldHorses);
  }

  useEffect(() => {

    setCurrentRoute('syndications');

    fetchInitData();

  }, []);

  return (
    <SyndicationDashboard
      breedingHorses={breedingHorses}
      chartData={chartData}
      isError={isError}
      isLoading={isLoading}
      raceHorses={raceHorses}
      soldHorses={soldHorses}
      totalBalances={totalBalances}
      tradeHorses={tradeHorses}
    />
  );
}

export default SyndicationDashboardContainer;
