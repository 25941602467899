import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LabeledData } from '../../../../components';

const useStyles = makeStyles(theme => ({
  labeledData: {
    flex: '0 0 100%',
    borderBottom: '1px solid #CCC',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexBasis: '50%',
    },
  }
}));

const HorseLabeledData = ({label, value}) => {
  const classes = useStyles();

  return (
    <div className={classes.labeledData}>
      <LabeledData
        label={label}
        value={value}
      />
    </div>
  );
}

export default HorseLabeledData;
