import React, { useContext, useState, useEffect } from 'react';

import InvestmentDashboard from './InvestmentDashboard';

import {
  fetchHorses,
  fetchInvestmentDetails,
  fetchMarketsData,
  fetchSummary,
  fetchStatements,
} from './actions';

import { RouteContext } from '../../App';

// String reference map
const StringMap = {
  au: 'Australia',
  nz: 'New Zealand',
  sg: 'Singapore',
  jp: 'Japan',
  hk: 'Hong Kong',
  nzb: 'New Zealand Bloodstock',
  mm: 'Magic Million(AUS)',
  ing: 'Inglis(AUS)',
};

const InvestmentDashboardContainer = () => {

  const { setCurrentRoute } = useContext(RouteContext);

  const [summary, setSummary] = useState({});
  const [statement, setStatement] = useState([]);
  const [statements, setStatements] = useState([]);
  const [horses, setHorses] = useState([]);
  const [breedingHorses, setBreedingHorses] = useState([]);
  const [tradeHorses, setTradeHorses] = useState([]);
  const [raceHorses, setRaceHorses] = useState([]);
  const [soldHorses, setSoldHorses] = useState([]);
  const [investmentDetails, setInvestmentDetails] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Market overview states
  const [ weanlingData, setWeanlingData ] = useState([]);
  const [ yearlingData, setYearlingData ] = useState([]);
  const [ rtrData, setRtrData ] = useState([]);
  const [ broodmareData, setBroodmareData ] = useState([]);
  const [ foalData, setFoalData ] = useState([]);
  const [ bettingData, setBettingData ] = useState([]);
  const [ prizeMoneyData, setPrizeMoneyData ] = useState([]);

  useEffect(() => {
    setCurrentRoute('investment');
    _initData();
  }, []);

  const _initData = async () => {
    const [
      summary,
      statements,
      horses,
      investmentDetails,
      marketsData,
    ] = await Promise.all([
      fetchSummary(),
      fetchStatements(),
      fetchHorses(),
      fetchInvestmentDetails(),
      fetchMarketsData(),
    ])

    if (investmentDetails.code && investmentDetails.code === 400 ) {
      setIsError(true);
      setIsLoading(false);
      return;
    }

    setSummary({
      ...summary,
      totalIncome: investmentDetails.totalIncome,
      totalExpense: investmentDetails.totalExpense,
      totalAssets: statements[statements.length - 1].totalAssets,
    });
    setStatement(statements[statements.length - 1]);
    setStatements(statements);
    setHorses(horses);
    _splitHorses(horses);
    _splitMarketsData(marketsData);
    setInvestmentDetails(investmentDetails);
    setIsLoading(false);
  }

  const _splitHorses = (horses) => {
    let breedingHorses = [];
    let tradeHorses = [];
    let raceHorses = [];
    let soldHorses = [];

    horses.forEach((horse) => {
      switch (horse.horseType) {
        case 'Breeding':
          breedingHorses.push(horse);
          break;
        case 'Trade':
          tradeHorses.push(horse);
          break;
        case 'Race':
          raceHorses.push(horse);
          break;
        case 'Sold':
          soldHorses.push(horse);
          break;
        default:
          break;
      }
    });

    setBreedingHorses(breedingHorses);
    setTradeHorses(tradeHorses);
    setRaceHorses(raceHorses);
    setSoldHorses(soldHorses);
  }

  const _splitMarketsData = (records) => {

    const recordsMap = {
      weanling: [],
      yearling: [],
      rtr: [],
      broodmare: [],
      foal: [],
      betting: [],
      prizeMoney: [],
    }

    records.forEach((record) => {

      const flattenRecord = {
        date: record.year,
      }

      const dataKeys = Object.keys(record.data);

      // Map each data key with name reference e.g. au -> Australia
      dataKeys.forEach((key) => {
        flattenRecord[StringMap[key]] = record.data[key];
      })

      recordsMap[record.type].push(flattenRecord);
    });

    setWeanlingData(recordsMap.weanling);
    setYearlingData(recordsMap.yearling);
    setRtrData(recordsMap.rtr);
    setBroodmareData(recordsMap.broodmare);
    setFoalData(recordsMap.foal);
    setBettingData(recordsMap.betting);
    setPrizeMoneyData(recordsMap.prizeMoney);
  }

  return (
    <InvestmentDashboard
      bettingData={bettingData}
      breedingHorses={breedingHorses}
      broodmareData={broodmareData}
      foalData={foalData}
      horses={horses}
      investmentDetails={investmentDetails}
      isError={isError}
      isLoading={isLoading}
      prizeMoneyData={prizeMoneyData}
      raceHorses={raceHorses}
      rtrData={rtrData}
      statement={statement}
      statements={statements}
      soldHorses={soldHorses}
      summary={summary}
      tradeHorses={tradeHorses}
      weanlingData={weanlingData}
      yearlingData={yearlingData}
    />
  );
}

export default InvestmentDashboardContainer;
