import React from 'react';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { SidebarNav } from './components';

import useStyles from './styles';

const Sidebar = ({drawerOpen, handleDrawerToggle, onSignOut}) => {

  const classes = useStyles();

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">

      {/* Mobie view */}
      <Hidden
        implementation="css"
        mdUp
      >
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          onClose={handleDrawerToggle}
          open={drawerOpen}
          variant="temporary"
        >
          <div className={classes.toolbar}>
            <img
              className={classes.logo}
              src="https://taniwhagroup.com/wp-content/uploads/2019/09/taniwha-logo-large-text.png"
            />
          </div>
          <Divider />
          <SidebarNav
            handleDrawerToggle={handleDrawerToggle}
            onSignOut={onSignOut}
          />
        </Drawer>
      </Hidden>

      {/* Desktop view */}
      <Hidden
        implementation="css"
        smDown
      >
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          variant="permanent"
        >
          <div className={classes.toolbar}>
            <img
              className={classes.logo}
              src="https://taniwhagroup.com/wp-content/uploads/2019/09/taniwha-logo-large-text.png"
            />
          </div>
          <Divider />
          <SidebarNav onSignOut={onSignOut} />
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Sidebar;
