import React from 'react';
import currency from 'currency.js';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Typography, Paper} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  totalBalance: {
    flex: 1,
  },
  balanceBreakdown: {
    display: 'flex',
  },
  breakdownItem: {
    flex: '1 0 auto',
  },
  gridItem: {
    textAlign: 'center',
    padding: theme.spacing(3),
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(7)': {
        borderBottom: 0,
      },
    },
    [theme.breakpoints.up('md')]: {
      '&:nth-child(n + 5)': {
        borderBottom: 0,
      },
      '&:not(:nth-child(4n))': {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  }
}));

const data = [
  {
    label: 'Operating Cash Flow',
    calc: (props) => props.netCashFlowsFromOperatingActivities,
    type: 'currency',
  },
  {
    label: 'Debt and Equity ratio',
    calc: (props) => props.totalLiabilities / props.totalEquity,
    type: 'numeric',
  },
  {
    label: 'Working Capital',
    calc: (props) => props.totalCurrentAssets - props.totalCurrentLiabilities,
    type: 'currency',
  },
  {
    label: 'Account Payable Turnover',
    calc: (props) => props.supplierPurchase / props.averageAccountsPayable,
    type: 'currency',
  },
  {
    label: 'Current Ratio',
    calc: (props) => props.totalCurrentAssets / props.totalCurrentLiabilities,
    type: 'numeric',
  },
  {
    label: 'working Capital Turnover Ratio',
    calc: (props) => props.totalTradingIncome / (props.totalCurrentAssets - props.totalCurrentLiabilities),
    type: 'numeric',
  },
  {
    label: 'Gross Profit Margin',
    calc: (props) => props.grossProfit / props.netProfit,
    type: 'numeric',
  },
  {
    label: 'Account Receivable Turnover',
    calc: (props) => props.creditSales / props.avarageAccountsReceivable,
    type: 'currency',
  },
]

const InvestmentReport = ({
  statement
}) => {

  const classes = useStyles();

  const reportItems = data.map(({label, calc, type}) => {

    const calcValue = calc(statement);
    const displayValue = type === 'currency' ?
      `${currency(calcValue).format()}` :
      calcValue.toFixed(2);

    return (
      <Grid
        key={label}
        item
        xs={12}
        sm={6}
        md={3}
        className={classes.gridItem}
      >
        <Typography
          color="textSecondary"
          variant="overline"
          style={{display: 'block'}}
        >
          {label}
        </Typography>
        <Typography variant="h3">
          {displayValue}
        </Typography>
      </Grid>
    );
  });

  return (
    <Paper>
      <Grid
        container
        spacing={0}
      >

        {reportItems}

      </Grid>
    </Paper>
  );
}

export default InvestmentReport
