import Parse from 'parse';

async function fetchInvestmentHorseInitData(investmentHorseId) {
  try {

    const payload = await Parse.Cloud.run('fetchInvestmentHorseInitData', {investmentHorseId});

    return payload;

  } catch (error) {
    console.error('fetchHorse error', error);
  }

}

export default fetchInvestmentHorseInitData;
