export default {
  ROOT: '/',
  LOGIN: '/login',
  HORSE: '/horse',
  INVESTMENT_HORSE: '/investment-horse',
  INVESTMENT: '/investment',
  COMPANY_TRANSACTIONS: '/company-transactions',
  SYNDICATIONS: '/syndications',
  USER_DETAILS: '/my-details',
}
