import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { HorseLabeledData } from '..';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  backLink: {
    display: 'inline-block',
    marginBottom: theme.spacing(3),
  },
  horseDataList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  horseImage: {
    width: '100%',
    marginBottom: theme.spacing(3),
  }
}));

const HorseProfile = ({horseDetails}) => {

  const classes = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12} md={6}>
            <div className={classes.horseDataList}>
              <HorseLabeledData
                label="Gender"
                value={horseDetails.gender}
              />
              <HorseLabeledData
                label="Date of Birth"
                value={horseDetails.dateOfBirth}
              />
              <HorseLabeledData
                label="Breeding Stud"
                value={horseDetails.breedingStud}
              />
              <HorseLabeledData
                label="Colour"
                value={horseDetails.coatColor}
              />
              <HorseLabeledData
                label="Sire"
                value={horseDetails.sire}
              />
              <HorseLabeledData
                label="Dam"
                value={horseDetails.dam}
              />
              <HorseLabeledData
                label="Trainer"
                value={horseDetails.trainer}
              />
              <HorseLabeledData
                label="Country of Origin"
                value={horseDetails.countryOfOrigin}
              />
              <HorseLabeledData
                label="Training Place"
                value={horseDetails.trainingPlace}
              />
            </div>
            {horseDetails.pedigreeURL ?
              <Button
                color="primary"
                component={Link}
                href={horseDetails.pedigreeURL}
                rel="noopener"
                target="_blank"
                variant="contained"
              >
                Pedigree
              </Button> :
              null
            }

          </Grid>
          <Grid item xs={12} md={6}>
            <img
              alt=""
              className={classes.horseImage}
              src={horseDetails.image}
            />
            <div>
              <Typography
                color="textSecondary"
                component="small"
                variant="overline"
              >
                Present Status
              </Typography>
              <Typography component="p">
                {horseDetails.presentStatus}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default HorseProfile;
