import React from 'react';
import currency from 'currency.js';
import MaterialTable from 'material-table';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';

import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles(theme => ({
  creditAmount: {
    color: '#e57373',
  },
  debitAmount: {
    color: '#4caf50',
  }
}));

const HorseTransactions = ({ transactions }) => {

  const classes = useStyles();

  const getCurrencyClasses = (type, value) => {

    if (value === 0) return;
    return type === 'expense' ? classes.creditAmount : classes.debitAmount;
  }

  const currencyDisplay = (type, value) => {
    return (
      <span className={getCurrencyClasses(type, value)}>
        {`${currency(value).format()}`}
      </span>
    );
  }

  const tableColumns = [
    { field: 'date', title: 'Date', type: 'date'},
    { field: 'type', title: 'Type' },
    { field: 'description', title: 'Description' },
    {
      field: 'sharePercentage',
      title: 'Shares',
      render: ({ sharePercentage }) => `${sharePercentage}%`,
    },
    {
      field: 'amount',
      title: 'Amount',
      type: 'numeric',
      render: ({ type, amount }) => currencyDisplay(type, amount)
    },
    {
      field: 'invoice',
      title: 'Invoice',
      type: 'numeric',
      render: ({ invoiceURL }) => (
        <>
          {invoiceURL ?
            <IconButton
              component={Link}
              href={invoiceURL}
              rel="noopener"
              target="_blank"
            >
              <SaveAltIcon />
            </IconButton> :
            null
          }
        </>
      )
    }
  ];

  return (
    <MaterialTable
      columns={tableColumns}
      data={transactions}
      options={{
        actionsColumnIndex: -1,
        search: false,
        paging: false,
        draggable: false,
        sorting: false,
        showTitle: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: '#3f51b5',
          color: '#FFF'
        }
      }}
      title="Related Transactions"
    />
  );
}

export default HorseTransactions;
